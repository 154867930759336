import React, { useRef } from 'react';

import _ from 'lodash';

import { graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';

import SEO from 'Components/SEO';
import ScrollUp from 'Components/ScrollUp';

import PublicationYears from 'Components/publications/Years';
import PublicationsList from 'Components/publications/List';

export default ({ data }) => {
  const { edges: publications } = data.allMarkdownRemark;

  const byYear = _
    .groupBy(publications, ({ node: publication }) => publication.frontmatter.publicationYear);
  const yearsDesc = Object.keys(byYear).sort((a, b) => b - a);

  const filtersRef = useRef(null);

  return (
    <PageWrapper>
      <SEO title="Publications" />
      <main className="page__main page__main--simple">
        <h1 className="page__title">Publications</h1>
        <ScrollUp scrollRef={ filtersRef } />
        <PublicationYears years={ yearsDesc } scrollRef={ filtersRef } />
        <PublicationsList publications={ publications } />
      </main>
    </PageWrapper>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {eq: "publication"},
        },
      },
      sort: {
        order: DESC,
        fields: frontmatter___date
      },
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            authors
            journal
            publicationYear
            date
            link
            pmid
          }
        }
      }
    }
  }
`;
