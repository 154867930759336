import React from 'react';

import _ from 'lodash';

import Publication from 'Components/publications/Publication';
import pubIcon from 'Assets/icons/pub_icon.svg';

const PublicationList = ({ publications }) => {
  const byYear = _
    .groupBy(publications, ({ node: publication }) => publication.frontmatter.publicationYear);
  const yearsDesc = Object.keys(byYear).sort((a, b) => b - a);

  return (
    <>
      { yearsDesc.map(year => (
        <div id={ year } className="publications__wrapper" key={ year }>
          <h3 className="publications__year">{ year }</h3>
          <ul className="publications__list">
            { byYear[year].map(({ node: publication }) => (
              <li className="publication__row" key={ publication.id }>
                <img className="publication__row__icon" src={ pubIcon } alt="publication" />
                <Publication publication={ publication.frontmatter } />
              </li>
            )) }
          </ul>
        </div>
      )) }
    </>
  );
};

export default PublicationList;
