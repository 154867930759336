import React, { useEffect, useState } from 'react';

const PublicationYears = ({ years, scrollRef }) => {
  const [ section, setSection ] = useState('');
  useEffect(() => {
    if (section) {
      document.getElementById(section).scrollIntoView({
        behavior: 'smooth',
      });
      setSection('');
    }
  }, [ section ]);
  return (
    <div className="publications__filter__wrapper" ref={ scrollRef }>
      <select
        className="publications__filter__select"
        onChange={ e => setSection(e.target.value) }
        value={ section }
      >
        <option
          className="publications__filter"
          value=""
        >
          Select a year...
        </option>
        { years.map(year => (
          <option
            className="publications__filter"
            key={ year }
            value={ year }
          >
            { year }
          </option>
        )) }
      </select>
    </div>
  );
};


export default PublicationYears;
