import React from 'react';

const Publication = ({ publication }) => (
  <div className="publication__row__content">
    <h4 className="publication__row__title">{ publication.title }</h4>
    <h5 className="publication__row__authors">{ publication.authors }</h5>
    <h6 className="publication__row__source"><em>{ publication.journal }</em></h6>
    { publication.pmid
      && (
      <>
        <a className="publication__row__link" href={ `https://www.ncbi.nlm.nih.gov/pubmed/${publication.pmid}` } target="_blank" rel="noopener noreferrer">PubMed</a>
      </>
      ) }
    { publication.link
      && (
      <>
        <a className="publication__row__link" href={ publication.link } target="_blank" rel="noopener noreferrer">{ publication.link }</a>
      </>
      ) }

  </div>
);

export default Publication;
